import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../shared/js/page';

import "./appsearch.scss";

dompack.onDomReady(() => {

  //console.log("appsearch js here");
  setTimeout( function() {
    $("#pageloadinglayer").addClass("hidden");
  }, 500);
     
  // fixme: jannes: only run if on such a page
  var qv = getQueryVar("q");
  //console.log(qv);
  if ( (qv !== undefined) && (qv.length>0) ) {
    //console.log("run", qv);
    $('form.appsearch input[name="q"]').val(qv);
  setTimeout( function() {
    console.log("call submit");
    $('form.appsearch').trigger("submit");
  }, 200);
  }

  $("form.appsearch").on("submit", function(_e) {
    //console.log("form.appsearch submit");
    _e.preventDefault();
    //_e.stopPropagation();

    $("form.appsearch .formfield").removeClass("error");
    $("form.appsearch .formfield .error").text("").addClass("hidden");
    $(".appsearchresults").html("");
    page.showLoader();

    var qval = $('form.appsearch input[name="q"]').val();
    var formdata = {
      action: "getAppSearchResults"
    , q: qval
    , ischkpersons: $('form.appsearch #chkpersons').prop("checked")
    , ischkclubs: $('form.appsearch #chkclubs').prop("checked")
    , ischkhelpdesk: $('form.appsearch #chkhelpdesk').prop("checked")
    , ischkwebsite: $('form.appsearch #chkwebsite').prop("checked")
    , ischkclubwebsites: $('form.appsearch #chkclubwebsites').prop("checked")
    , sortorder: $('form.appsearch #selectSortorder').val()
    };
    //console.log("formdata:", formdata);

    if (qval.trim().length<3) {
      //console.log("q should have at least 3 chars");
      $("form.appsearch .form-line .formfield .error").text("zoekterm dient minimaal 3 tekens te hebben").removeClass("hidden");
    }

    $.ajax({
      url: "/data.shtml",
      type: "POST",
      data: formdata,
      complete: function() {
        //_form.removeClass('is-uploading');
      },
      success: function(_data) {
        setTimeout(function() {
          page.hideLoader();
        }, 500);
        //console.log({successdata: _data});
        if (_data && _data.ok) {
          $('form.appsearch input[name="q"]').blur(); // remove focus from q input
          //console.log("result appsearch: ", _data);
          if (_data.items) {
            var resultshtml = "<h2>Zoekresultaten</h2>";
            if (_data.items.length>0) {
            $.each(_data.items, function(_index, _item) {
              var addclass = "";
              var iconclass = "";
              var resulttype = "";
              if (_item.ismember) {
                addclass = "member";
                iconclass = "fa-male";
                resulttype = "lid";
              } else if (_item.isclub) {
                addclass = "club";
                iconclass = "fa-home";
                resulttype = _item.fmt_club_type + " club";
              } else if (_item.ishelpdesk) {
                addclass = "helpdesk";
                iconclass = "fa-question-circle";
                resulttype = "helpdesk";
              } else if (_item.iswebsite) {
                addclass = "website";
                iconclass = "fa-globe";
                resulttype = "website";
              }
              
              resultshtml = resultshtml + '<div class="result';
              if (addclass !== "") {
                resultshtml = resultshtml + ' ' + addclass;
              }
              resultshtml = resultshtml + '">';
              resultshtml = resultshtml + '<a href="' + _item.viewurl + '" title="">';
              resultshtml = resultshtml + '  <div class="row header flex">';
              resultshtml = resultshtml + '    <span class="source"><i class="fas ' + iconclass + '"></i> ' + resulttype + '</span>';
              if (_item.iswebsite) {
                resultshtml = resultshtml + '    <span class="date">' + _item.fmtsortdate + '</span>';
              }
              resultshtml = resultshtml + '  </div>';

              if (_item.ismember) {
                resultshtml = resultshtml + '  <div class="row flex">';
                resultshtml = resultshtml + '  <img class="thumb" src="' + _item.wrappedphoto.link + '" alt="" />';
                resultshtml = resultshtml + '  <div class="vflex">';
                resultshtml = resultshtml + '  <h3>' + _item.wrd_fullname + '</h3>';
                resultshtml = resultshtml + '  <span class="club">' + _item.clubname + ' (' + _item.fmt_club_type + ')' + '</span>';
                resultshtml = resultshtml + '  <span class="classification">' + _item.classification_title + '</span>';
                resultshtml = resultshtml + '  </div>';
                resultshtml = resultshtml + '  </div>';
              } else if (_item.isclub) {
                resultshtml = resultshtml + '  <div class="row flex">';
                resultshtml = resultshtml + '  <img class="thumb" src="' + (_item.wrappedimg ? _item.wrappedimg.link : "/resources/img/rotary-logo-wheel.svg") + '" alt="" />';
                resultshtml = resultshtml + '  <div class="vflex">';
                resultshtml = resultshtml + '  <h3>' + _item.wrd_orgname + '</h3>';
                resultshtml = resultshtml + '  <span class="meeting_datetime">' + _item.meeting_day + " " + _item.meeting_time + '</span>';
                resultshtml = resultshtml + '  </div>';
                resultshtml = resultshtml + '  </div>';
              } else if (_item.ishelpdesk) {
                resultshtml = resultshtml + '  <div class="row flex">';
                resultshtml = resultshtml + '  <i class="thumb fas fa-question"></i>'; 
                resultshtml = resultshtml + '  <div class="vflex">';
                resultshtml = resultshtml + '  <h3>' + _item.summary + '</h3>';
                resultshtml = resultshtml + '  <p class="summary">' + _item.startcontent + '</p>';
                resultshtml = resultshtml + '  </div>';
                resultshtml = resultshtml + '  </div>';
              } else if (_item.iswebsite) {
                resultshtml = resultshtml + '  <div class="row flex">';
                resultshtml = resultshtml + '  <img class="thumb" src="/resources/img/rotary-logo-wheel.svg" alt="" />';
                resultshtml = resultshtml + '  <div class="vflex">';
                resultshtml = resultshtml + '  <h3>' + _item.title + '</h3>';
                resultshtml = resultshtml + '  <p class="summary">' + _item.summary + '</p>';
                //resultshtml = resultshtml + '  <p class="url">' + _item.viewurl + '</p>';
                resultshtml = resultshtml + '  <p class="url">' + _item.path + '</p>';
                resultshtml = resultshtml + '  </div>';
                resultshtml = resultshtml + '  </div>';
              }
              resultshtml = resultshtml + '</a>';
              resultshtml = resultshtml + '</div>';

            }); // each
            }
            else {
              resultshtml = resultshtml + '<p>Geen resultaten gevonden voor de zoekterm.</p>';
            }
            $(".appsearchresults").html(resultshtml);
          }
        }
        else {
          //console.log("not ok, result appsearch: ", _data);
          if (_data.status === -1) {
            // not logged in
            alert("Je bent niet (meer) ingelogd. Log opnieuw in.");
          }
          else {
            //console.log("data forminputerror: ", _data.forminputerrors);
            for (var forminputerror of _data.forminputerrors) {
              //console.log("forminputerror: ", forminputerror);
              //console.log("name: ", forminputerror.name);
              var formfieldselector = 'form.appsearch #' + forminputerror.name + 'Field.formfield';
              //console.log("formfieldselector", formfieldselector);
              var formfield = $(formfieldselector);
              if (formfield.length>0) {
                formfield.addClass("error");
                var errors = "";
                for (var error of forminputerror.errors) {
                  if (errors.length>0) {
                    errors = errors + "\n";
                  }
                  errors = errors + error;
                }
                //console.log("errors", errors);
                var smallerror = formfield.find("small.error");
                smallerror.text(errors);
                smallerror.removeClass("hidden");
              }
            }
          }
        }
      },
      error: function() {
        // Log the error, show an alert, whatever works for you
        setTimeout(function() {
          page.hideLoader();
        }, 1000);
        alert("Er is iets fout gegaan bij het uitvoeren van de zoekopdracht. Probeer het (later) opnieuw.");
      }
    }); // ajax

  });

});

function getQueryVar(_varname)
{
  var querystring = window.location.search.substring(1);
  var queryvars = querystring.split("&");
  for(var i=0; i<queryvars.length; i++) {
    var queryvar = queryvars[i].split("=");
    if (queryvar[0] == _varname) {
      return queryvar[1];
    }
  }
  return "";
}
