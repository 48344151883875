
import './personsspotlight.scss';

import * as dompack from 'dompack';
import $ from 'jquery';

import Swiper, { Navigation,Pagination,Autoplay } from 'swiper';

dompack.onDomReady(() => {

});


class WidgetPersonsSpotlightSlider {
  // node = .w-sliderimages
  constructor(node) {
    const $node = $(node);

    const data_autoplay = $(node).data("autoplay") || false;
    const data_autoplaydelay = $(node).data("autoplaydelay") || "default";
    const data_loop = $(node).data("loop") || false;
    const data_direction = $(node).data("direction") || "horizontal";
    //console.log({ 'autoplay': data_autoplay, 'autoplaydelay': data_autoplaydelay, 'loop': data_loop });

    const $swiper = $(node).find('.swiper-container').get(0);
    const numSlides = $(node).find('.swiper-slide').length;
    if (numSlides <= 1) {
      return;
    }

    $(node).addClass('has-slides');

    let classthis = this;
    this.wid = $(node).data("wid");
    //console.log("wid: ", this.wid);
    this.localstoragekey = "rotary.nl.w-" + this.wid + ".seenuuids";
    this.alluuids = [];
    $node.find(".widget--personsspotlight__otherperson").each( function(_index) {
      let $this = $(this);
      classthis.alluuids.push($this.data("uuid"));
    });
    //console.log("alluuids: ", this.alluuids);

    this.localuuids = JSON.parse(window.localStorage.getItem(this.localstoragekey)) || [];
    //console.log("localuuids: ", this.localuuids);

    this.viewuuids = [...this.alluuids];
    this.viewuuids = this.viewuuids.filter(item => !this.localuuids.includes(item))
    //console.log("viewuuids: ", this.viewuuids);
    if (this.viewuuids.length===0) {
      this.resetUUIDs();
    }

    var config = {
      modules: [Navigation, Pagination, Autoplay],
      //effect: "fade",
      loop: data_loop,
      direction: data_direction,
      slidesPerView: 1,  // auto
      //centeredSlides: true,
      spaceBetween: 10, //30
      grabCursor: true,
      navigation: {
        prevEl: '.widget--personsspotlight__slider-navbutton--prev',
        nextEl: '.widget--personsspotlight__slider-navbutton--next',
      },
      //pagination: {
      //  el: '.swiper-pagination',
      //  type: 'bullets',
      //  clickable: true,
      //},
      breakpoints: {
        400: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
      },
      on: {
        click: function(_e) {
          var clicked = $(_e.target);
          //console.log("clicked:", clicked, this.clickedSlide);
          let $clickedslide = $(this.clickedSlide);
          //console.log("clickedslide:", $clickedslide);
          //console.log($clickedslide.find(".widget--personsspotlight__person__name").html());
          //$(".widget--personsspotlight__person .widget--personsspotlight__person__quote p").text($clickedslide.find(".widget--personsspotlight__person__quote").text());
          //$(".widget--personsspotlight__person .widget--personsspotlight__person__name h3").text($clickedslide.find(".widget--personsspotlight__person__name").text());
          //$(".widget--personsspotlight__person .widget--personsspotlight__person__text").html($clickedslide.find(".widget--personsspotlight__person__text").html());
          //$(".widget--personsspotlight__person .widget--personsspotlight__person__image").html($clickedslide.find(".widget--personsspotlight__person__image").html());
          let otherpersonuuid = $clickedslide.find(".widget--personsspotlight__otherperson").data("uuid") || "";
          //console.log("otherpersonuuid:", otherpersonuuid);
          if (otherpersonuuid !== "") {
            classthis.selectByUUID(otherpersonuuid);
          }
        }
      },
    }
    if (data_autoplay) {
      let autoplaydelay = 3000;
      switch(data_autoplaydelay) {
        case "short":
          autoplaydelay = 2000;
          break;
        case "long":
          autoplaydelay = 6000;
          break;
        case "default":
          autoplaydelay = 4000;
          break;
        default:
          autoplaydelay = 3000;
      }
      config["autoplay"] = {
        delay: autoplaydelay,
      }
    }
    //console.log("config", config);

    this.myswiper = new Swiper($swiper, config);
    this.selectRandom();
  }
  logUUIDs() {
    //console.log({ 'alluuids': this.alluuids, 'localuuids': this.localuuids, 'viewuuids': this.viewuuids });
  }
  resetUUIDs() {
    //console.log("resetting uuids");
    this.viewuuids=[...this.alluuids];
    this.localuuids=[];
    window.localStorage.removeItem(this.localstoragekey);
    this.logUUIDs();
  }
  addUUIDToLocalStorage(_uuid) {
    if (! this.localuuids.includes(_uuid)) {
      this.localuuids.push(_uuid);
      window.localStorage.setItem(this.localstoragekey, JSON.stringify(this.localuuids));
      //console.log({ 'add_uuid': _uuid });
      this.logUUIDs();
    }
  }
  selectRandom() {
    const randomindex = Math.floor(Math.random() * this.viewuuids.length);
    const randomuuid = this.viewuuids[randomindex];
    //console.log("select_random", randomuuid);
    this.selectByUUID(randomuuid);
  }
  selectByUUID(_uuid)
  {
    const selector = ".widget--personsspotlight__otherperson[data-uuid='" + _uuid + "']";
    let $otherperson = $(selector);
    if ($otherperson.length>0) {
      $(".widget--personsspotlight__person .widget--personsspotlight__person__quote p").text($($otherperson[0]).find(".widget--personsspotlight__person__quote").text());
      $(".widget--personsspotlight__person .widget--personsspotlight__person__name h3").text($($otherperson[0]).find(".widget--personsspotlight__person__name").text());
      $(".widget--personsspotlight__person .widget--personsspotlight__person__text").html($($otherperson[0]).find(".widget--personsspotlight__person__text").html());
      $(".widget--personsspotlight__person .widget--personsspotlight__person__image").html($($otherperson[0]).find(".widget--personsspotlight__person__image").html());
    }
    //console.log("selector:", selector, "otherperson: ", $otherperson[0]);

    const indexselected = this.viewuuids.indexOf(_uuid);
    this.viewuuids.splice(indexselected, 1);

    this.addUUIDToLocalStorage(_uuid)
  }
}

dompack.register('.widget--personsspotlight', node => new WidgetPersonsSpotlightSlider(node) );
