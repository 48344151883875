import './slider.scss';

import * as dompack from 'dompack';
import $ from 'jquery';

//import Swiper from 'swiper';
//import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import Swiper, { Navigation,Pagination,Autoplay } from 'swiper';

//import 'swiper/css';
//import 'swiper/css/navigation';
//import 'swiper/css/pagination';

//import VideoPlayersController from '@mod-weshared/js/media/videoplayer';

//console.log("processing slider.es");

Swiper.use([Navigation,Pagination,Autoplay]);

dompack.onDomReady(() => {
  //console.log("onDomReady slider.es");
});

class WidgetSlider {
  // node = .w-sliderimages
  constructor(node) {
    const data_autoplay = $(node).data("autoplay") || false;
    const data_autoplaydelay = $(node).data("autoplaydelay") || "default";
    const data_loop = $(node).data("loop") || true;
    const data_direction = $(node).data("direction") || "horizontal";
    //console.log({ 'autoplay': data_autoplay, 'autoplaydelay': data_autoplaydelay, 'loop': data_loop });

    const $swiper = $(node).find('.swiper-container').get(0);
    const numSlides = $(node).find('.swiper-slide').length;
    if (numSlides <= 1) {
      return;
    }

    $(node).addClass('has-slides');

    var config = {
      modules: [Navigation, Pagination, Autoplay],
      //effect: "fade",
      loop: data_loop,
      direction: data_direction,
      slidesPerView: 'auto',  // auto
      centeredSlides: true,
      spaceBetween: 0, //30
      grabCursor: true,
      navigation: {
        prevEl: '.widget--slider__navbutton--prev',
        nextEl: '.widget--slider__navbutton--next',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    }
    if (data_autoplay) {
      let autoplaydelay = 3000;
      switch(data_autoplaydelay) {
        case "short":
          autoplaydelay = 2000;
          break;
        case "long":
          autoplaydelay = 6000;
          break;
        case "default":
          autoplaydelay = 4000;
          break;
        default:
          autoplaydelay = 3000;
      }
      config["autoplay"] = {
        delay: autoplaydelay,
      }
    }
    //console.log("config", config);

    new Swiper($swiper, config);
  }
}

dompack.register('.widget--slider', node => new WidgetSlider(node) );
